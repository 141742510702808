<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-card-code title="  أضافة خدمة">
          <b-row>
            <b-col
              md="3"
              xl="3"
            >
              <b-form-group>
                <b-form-checkbox
                  v-model="newMainServices"
                  plain
                  class="custom-control-warning"
                >
                  <label> إضافة خدمة اساسية </label>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="newMainServices">
            <b-col
              md="3"
              xl="3"
            >
              <b-form-group label-for="delimiter">
                <label> اسم الخدمة الاساسية</label>
                <b-form-input
                  id="input-default"
                  v-model="ServName"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="1"
              xl="3"
            >
              <b-button
                variant="purple"
                @click="addServ"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
                />
                <span class="align-middle"> اضافة الخدمة</span>
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              md="2"
              xl="2"
            >
              <b-form-group>
                <b-form-checkbox
                  v-model="newSubServ"
                  plain
                  class="custom-control-warning"
                >
                  <label> إضافة خدمة فرعية</label>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="newSubServ">
            <b-col
              md="4"
              xl="3"
            >
              <!-- <validation-provider #default="{ errors }" name="Weak" rules="required"> -->
              <b-form-group label-for="time">
                <label> الخدمة الاساسية</label>
                <v-select
                  v-model="selectedService"
                  :reduce="(val) => val.value"
                  :options="optionServices"
                />
              </b-form-group>
              <!-- <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
                 </validation-provider> -->
            </b-col>

            <!-- delimiters -->
            <b-col
              md="3"
              xl="3"
            >
              <b-form-group label-for="delimiter">
                <label> الخدمة</label>
                <b-form-input
                  id="input-default"
                  v-model="SubServName"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="4"
              xl="3"
            >
              <!-- <validation-provider #default="{ errors }" name="Weak" rules="required"> -->
              <b-form-group label-for="time">
                <label> الفئة العمرية </label>
                <v-select
                  v-model="age_range"
                  :reduce="(val) => val.value"
                  :options="optionAgeRange"
                />
              </b-form-group>
              <!-- <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
                 </validation-provider> -->
            </b-col>
            <b-col
              md="4"
              xl="3"
            >
              <!-- <validation-provider #default="{ errors }" name="Weak" rules="required"> -->
              <b-form-group label-for="time">
                <label>  الجنس</label>
                <v-select
                  v-model="gender"
                  :reduce="(val) => val.value"
                  :options="optionGender"
                />
              </b-form-group>
              <!-- <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
                 </validation-provider> -->
            </b-col>
            <b-col
              md="4"
              xl="3"
            >
              <!-- <validation-provider #default="{ errors }" name="Weak" rules="required"> -->
              <b-form-group label-for="time">
                <label>  عدد مرات الاستفادة</label>
                <v-select
                  v-model="one_time_assign"
                  :reduce="(val) => val.value"
                  :options="optionTimeAssign"
                />
              </b-form-group>
              <!-- <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
                 </validation-provider> -->
            </b-col>
            <b-col
              md="1"
              xl="3"
            >
              <b-button
                variant="purple"
                @click="addSubServ"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
                />
                <span class="align-middle"> اضافة الخدمة الفرعية</span>
              </b-button>
            </b-col>
          </b-row>
        </b-card-code>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BRow,
  BCol,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,

  BFormDatepicker,
  BFormCheckbox,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BButton,
  BForm,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

import { useToast } from 'vue-toastification/composition'

export default {
  name: '',

  components: {
    BDropdown,

    ToastificationContent,
    BDropdownItem,
    BDropdownItemButton,
    BFormInvalidFeedback,
    VueGoodTable,
    BForm,
    ValidationObserver,
    ValidationProvider,

    BCardCode,
    BButton,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,
    Cleave,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BRow,

    BCol,
  },

  data() {
    return {
      newMainServices: false,
      newSubServ: false,

      ServName: '',
      SubServName: '',
      townName: '',
      city: '',
      optionServices: [],
      optiongovernorate: [],
      optionCity: [],
      selectedService: '',
      optionGender: [{
        value: 1,
        label: 'ذكر',
      },
      {
        value: 2,
        label: 'انثى',
      },
      {
        value: 0,
        label: 'ذكر و انثى',
      }],
      optionAgeRange: [
        {
          value: 1,
          label: 'مسن',
        },
        {
          value: 2,
          label: 'شاب',
        },
        {
          value: 0,
          label: ' طفل ',
        },
        {
          value: 3,
          label: ' جميع الفئات العمرية ',
        },
      ],
      optionTimeAssign: [
        {
          value: 1,
          label: 'مرة',
        },
        {
          value: 0,
          label: 'اكثر',
        },
      ],
      age_range:'',
      gender:'',
      one_time_assign:'',

    }
  },

  computed: {},

  created() {
    this.getServices()
  },

  methods: {
    addServ() {
      const data = {
        name: this.ServName,
      }
      this.$http.post('/api/v1/main_services', data).then(res => {
        // console.log(res);
        this.ServName=''
        this.$swal({
          title: 'تم إضافة الخدمة بنجاح',
          icon: 'success',
          confirmButtonText: 'موافق',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
      this.getServices()
    },
    getServices() {
      this.$http.get('/api/v1/main_services').then(res => {
        const services = res.data.data
        services.forEach(el => {
          this.optionServices.push({ value: el.id, label: el.name })
        })
      })
    },

    addSubServ() {
      const data = {
        name: this.SubServName,
        main_service_id: this.selectedService,
        age_range: this.age_range,
        gender: this.gender,
        one_time_assign: this.one_time_assign,
      }
      // console.log(data);
      this.$http.post('/api/v1/proposal_services', data).then(res => {
        // console.log(res);
        this.SubServName=''
        this.selectedService=''
        this.age_range=''
       this.gender=''
         this.one_time_assign=''
        this.$swal({
          title: 'تم إضافة الخدمة الفرعية بنجاح',
          icon: 'success',
          confirmButtonText: 'موافق',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }).catch((error) => {
        this.$swal({
          text:`${error.response.data.message}`,
      icon: "error",

        confirmButtonText: "موافق",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
      });
    },

    //
  },
}
</script>

<style scoped>
label {
  color: rgba(112, 192, 203, 255);
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  background-color: #7e7a9f;
  margin-top: 25px;
}

span {
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.align-left {
  left: 50px;
}
.but {
  top: 40px;
}
</style>
